/* eslint-disable max-len */
import Login from '../Containers/Login/Login';
import ForgotPassword from '../Containers/Login/ForgotPassword';
import HistorialMMFM from '../Containers/HistorialMMFM/HistorialMMFM';

const routesList = [
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/',
  },
  {
    auth: false,
    component: Login,
    exact: true,
    name: 'login',
    path: '/login',
  },
  {
    auth: false,
    component: ForgotPassword,
    exact: true,
    name: 'forgotPwd',
    path: '/forgot-password',
  },
  {
    auth: true,
    component: HistorialMMFM,
    exact: true,
    name: 'historialMMFM',
    path: '/historialMMFM',
  },
];

export default routesList;
