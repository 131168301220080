import React from 'react';
import Typography from '@material-ui/core/Typography';

const Copyright = () => {
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      align="center"
    >
      Copyright ©{` ${new Date().getFullYear()}`} Partido
      Liberación Nacional
      <br />
      TELEFONOS: 2549-5500 / 2549-5504 CENTRAL TELEFÓNICA.
    </Typography>
  );
};

export default Copyright;
