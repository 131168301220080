import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
  CircularProgress,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Lodash from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import clsx from 'clsx';
import style from './styles';

import { forgotPwd } from '../../Core/Api/accountApi';

const ForgotPassword = props => {
  const { classes } = props;

  const [submitError, setSubmitError] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitErrorMsg, setSubmitErrorMsg] = useState('');
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState(
    '',
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = { nickname: '' };

  const validationSchema = Yup.object({
    nickname: Yup.string().required('Campo Requerido'),
  });

  const resetPassword = formik => () => {
    const { nickname } = formik.values;
    setSubmitError(false);
    setSubmitErrorMsg('');
    setIsSubmitting(true);

    if (nickname.length > 0) {
      forgotPwd({
        nickname,
      }).then(res => {
        const isPwdUpdated = Lodash.get(
          res,
          ['data', 'response', 'status'],
          false,
        );

        const useremail = Lodash.get(
          res,
          ['data', 'response', 'email'],
          false,
        );

        if (isPwdUpdated) {
          setSubmitSuccess(true);
          formik.setFieldValue('nickname', '');
          setSubmitSuccessMsg(
            `Su Contraseña ha sido actualizada y enviada al correo: ${useremail}`,
          );
          // window.location.href = '/dashboard';
        } else {
          setSubmitError(true);
          setSubmitErrorMsg(
            'Lo sentimos el usuario ingresado no se encuentra registrado',
          );
        }
        setIsSubmitting(false);
      });
    } else {
      setSubmitError(true);
      setIsSubmitting(false);
      setSubmitErrorMsg('Favor ingrese el usuario');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
  });

  ForgotPassword.propTypes = {};

  return (
    <>
      <Container
        maxWidth={false}
        className={classes.loginBox}
      >
        <h2 className={classes.title}>
          Recuperación de Contraseña
        </h2>
        <h4 className={classes.subtitle}>
          DIGITE SU SU USUARIO DE INGRESO AL SISTEMA
        </h4>
        <form className={classes.form}>
          <label
            htmlFor="nickname"
            className={classes.labelText}
          >
            Usuario
          </label>
          <input
            className={clsx(
              classes.input,
              formik.errors.email && classes.inputError,
            )}
            id="nickname"
            name="nickname"
            label="nickname"
            placeholder=""
            onChange={formik.handleChange}
            value={formik.values.nickname}
          />
          {submitSuccess && (
            <div className={classes.formError}>
              <Alert severity="success">
                {submitSuccessMsg}
              </Alert>
            </div>
          )}
          {submitError && (
            <div className={classes.formError}>
              <Alert severity="error">
                {submitErrorMsg}
              </Alert>
            </div>
          )}
          <Button
            className={classes.submitButton}
            disabled={isSubmitting}
            variant="contained"
            color="primary"
            onClick={resetPassword(formik)}
            type="button"
          >
            {isSubmitting ? (
              <CircularProgress
                className={classes.spinner}
              />
            ) : (
              'Restablecer Contraseña'
            )}
          </Button>

          <p className={classes.backToLogin}>
            <a href="/login">Volver</a>
          </p>

          <p
            style={{
              marginTop: '40px',
              fontSize: '.8em',
              textAlign: 'center',
            }}
          >
            Copyright ©{` ${new Date().getFullYear()}`}{' '}
            Partido Liberación Nacional
            <br />
            TELEFONOS: 2549-5500 / 2549-5504 CENTRAL
            TELEFÓNICA
          </p>
        </form>
      </Container>
    </>
  );
};

export default withStyles(style)(ForgotPassword);
