import React, { useState } from 'react';
import Lodash from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import { Container, Grid, Link } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MoreIcon from '@material-ui/icons/MoreVert';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';
import PersonIcon from '@material-ui/icons/Person';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import NestedMenuItem from './NestedMenuItem.tsx';
import style from './styles';

import {
  useIGESAvailable,
  useLoginGESAvailable,
} from '../../Core/Api/periodoApi';

const menuAsambleasDistritales = [
  {
    id: '582e4aeb-316c-412b-8e5d-fcc67e6a059b',
    name: 'Formularios',
    children: [
      {
        id: 'formulario/papeletas-distritales',
        name: 'Papeletas de Mov. Distritales',
      },
      {
        id: 'formulario/papeletas-movimientos-cantonales',
        name: 'Papeletas de Mov. Cantonales',
      },
      {
        id: 'formulario/papeletas-movimientos-provinciales',
        name: 'Papeletas de Mov. Provinciales',
      },
      {
        id: 'formulario/papeletas-movimientos-nacionales',
        name: 'Papeletas de Mov. Nacionales',
      },
      {
        id: 'formulario/presidente-sectores',
        name: 'Presidente de Sectores',
      },
      {
        id: 'formulario/papeletas-sectores',
        name: 'Papeletas de Sectores',
      },
      {
        id: 'formulario/hoja-informacion',
        name: 'Hoja de Información',
      },
      /*
      {
        id: 'ac999021-2e94-4e8b-8392-5140f4eeb0f6',
        name: 'Item 1.2',
        children: [
          {
            id: '21ee291d-2f6a-4e46-8e49-df2768d1775f',
            name: 'Item 1.2.1',
          },
          {
            id: 'ac999021-2e94-4e8b-8392-5140f4eeb0f6',
            name: 'Item 1.2.2',
          },
        ],
      },
      */
    ],
  },
  {
    id: '8be77a64-b963-4aef-addb-749cd6c845dc',
    name: 'Consulta de Inscripciones',
    children: [
      {
        id: 'consulta-publica/papeletas-distritales',
        name: 'Papeletas Distritales',
      },
      {
        id:
          'consulta-publica/papeletas-movimientos-cantonales',
        name: 'Papeletas Cantonales',
      },
      {
        id:
          'consulta-publica/papeletas-movimientos-provinciales',
        name: 'Papeletas Provinciales',
      },
      {
        id:
          'consulta-publica/papeletas-movimientos-nacionales',
        name: 'Papeletas Nacionales',
      },
      {
        id: 'consulta-publica/presidente-sectores',
        name: 'Presidente de Sectores',
      },
      {
        id: 'consulta-publica/papeletas-sectores',
        name: 'Papeletas de Sectores',
      },
    ],
  },
  {
    id: '8be77a64-b963-4aef-addb-749cd6c845dd',
    name: 'Registros Electorales',
    children: [
      {
        id: 'registros-electorales/miembros-mesa',
        name: 'Miembros de Mesa',
      },
      {
        id: 'registros-electorales/fiscales-mesa',
        name: 'Fiscales de Mesa',
      },
      {
        id: 'registros-electorales/fiscales-generales',
        name: 'Fiscales Generales',
      },
    ],
  },
];

const Header = props => {
  const { classes } = props;

  const [anchorElMAD, setAnchorElMAD] = useState(null);

  const handleClickMAD = event => {
    setAnchorElMAD(event.currentTarget);
  };

  const handleCloseMAD = () => {
    setAnchorElMAD(null);
  };

  const handleItemClickMAD = link => {
    setAnchorElMAD(null);
    window.location.href = `/${link}`;
  };

  // Se valida si esta habilitada la opcion (Registro Gestores)
  const isRegistroGESAvailable = useIGESAvailable();
  const registroGESAvailable = Lodash.get(
    isRegistroGESAvailable,
    ['data', 'response'],
    null,
  );

  // Se valida si esta habilitada la opcion (Login Gestores)
  const isLoginGESAvailable = useLoginGESAvailable();
  const loginGESAvailable = Lodash.get(
    isLoginGESAvailable,
    ['data', 'response'],
    null,
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [
    anchorElectorales,
    setAnchorElectorales,
  ] = useState(null);
  const [
    mobileMoreAnchorEl,
    setMobileMoreAnchorEl,
  ] = useState(null);

  const menuId = 'primary-search-account-menu';
  const menuId2 = 'consultas-publicas-menu';
  const menuIdElectorales = 'registros-electorales-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const isMenuOpen = Boolean(anchorEl);
  const isMenu2Open = Boolean(anchorEl2);
  const isMenuElectoralesOpen = Boolean(anchorElectorales);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleAdjudicacionPuestos = event => {
    setAnchorEl2(event.currentTarget);
  };

  const handleElectoralesMenuOpen = event => {
    setAnchorElectorales(event.currentTarget);
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMobileMenu2Close = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMenu2Close = () => {
    setAnchorEl2(null);
    handleMobileMenu2Close();
  };

  const handleMenuRegistroElectClose = () => {
    setAnchorElectorales(null);
    handleMobileMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/convencion-interna">
          Convención Interna
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/papeletas-distritales">
          Papeletas Distritales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/papeletas-movimientos-cantonales">
          Papeletas Cantonales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/papeletas-movimientos-provinciales">
          Papeletas Provinciales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/papeletas-movimientos-nacionales">
          Papeletas Mov. Nacionales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/presidente-sectores">
          Presidencia de Sectores
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuClose}>
        <Link href="/escrutinio-votacion/papeletas-sectores">
          Papeletas de Sectores
        </Link>
      </MenuItem>
    </Menu>
  );

  const renderMenuAdjudicacionPuestos = (
    <Menu
      anchorEl={anchorEl2}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId2}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenu2Open}
      onClose={handleMenu2Close}
    >
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/delegados-distritales">
          Delegados Distritales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/comite-politico-cantonal">
          Comité Político Cantonal
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/movimiento-representante-provinciales">
          Representantes Provinciales de Movimientos
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/movimientos-nacionales">
          Mov. Nacionales
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/presidente-sectores">
          Presidencia de Sectores
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenu2Close}>
        <Link href="/adjudicacion-puestos/sectores">
          Repre. al Organo Consultivo Nac. (Sectores)
        </Link>
      </MenuItem>
    </Menu>
  );

  const renderRegistrosElectMenu = (
    <Menu
      anchorEl={anchorElectorales}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuIdElectorales}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuElectoralesOpen}
      onClose={handleMenuRegistroElectClose}
    >
      <MenuItem onClick={handleMenuRegistroElectClose}>
        <Link href="/registros-electorales/miembros-mesa">
          Miembros de Mesa
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuRegistroElectClose}>
        <Link href="/registros-electorales/fiscales-mesa">
          Fiscales de Mesa
        </Link>
      </MenuItem>
      <MenuItem onClick={handleMenuRegistroElectClose}>
        <Link href="/registros-electorales/fiscales-generales">
          Fiscales Generales
        </Link>
      </MenuItem>
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <Button color="inherit">Inicio</Button>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <Button color="inherit">
          Escrutinio de Votación
        </Button>
      </MenuItem>
      <MenuItem>
        <Button color="inherit">Consulta Electores</Button>
      </MenuItem>
    </Menu>
  );

  return (
    <Container maxWidth={false}>
      <Grid container>
        <Grid item xs={6}>
          <img
            src="/logo2020.png"
            className={classes.imgLogo}
            alt="LogoPLN"
            style={{ float: 'left' }}
          />
          <div
            style={{
              float: 'left',
              marginTop: 20,
              marginLeft: 120,
            }}
          >
            <h1
              style={{
                color: '#666',
                fontWeight: 'normal',
                fontSize: 18,
                textAlign: 'left',
              }}
            >
              <span
                style={{
                  fontSize: 14,
                }}
              >
                Partido Liberación Nacional
              </span>{' '}
              <br />
              PLN CONSULTAS <br />
              <span
                style={{
                  fontSize: 14,
                }}
              >
                2022
              </span>
            </h1>
          </div>
        </Grid>
        <Grid item xs={6} style={{ textAlign: 'right' }}>
          <ul
            className={classes.loginAccess}
            style={{ marginTop: 40 }}
          >
            {process.env
              .REACT_APP_OPTION_REGISTRO_GESTORES ===
              'enabled' &&
              registroGESAvailable && (
                <li>
                  <LibraryBooksIcon />
                  <Link href="/registro-gestores">
                    <span>Registro Gestores</span>
                  </Link>
                </li>
              )}
            {process.env.REACT_APP_OPTION_LOGIN_GESTORES ===
              'enabled' &&
              loginGESAvailable && (
                <li>
                  <PersonIcon />
                  <Link href="/login">
                    <span>Ingresar</span>
                  </Link>
                </li>
              )}
          </ul>
          <ul
            className={classes.socialLinks}
            style={{ marginTop: 40 }}
          >
            <li>
              <Link
                href="https://www.facebook.com/partidoliberacionnacional"
                target="_blank"
              >
                <FacebookIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://twitter.com/plncr"
                target="_blank"
              >
                <TwitterIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.youtube.com/user/plndigital?feature=watch"
                target="_blank"
              >
                <YouTubeIcon />
              </Link>
            </li>
            <li>
              <Link
                href="https://www.instagram.com/partidoliberacionnacional/"
                target="_blank"
              >
                <InstagramIcon />
              </Link>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <AppBar position="static">
          <Toolbar>
            <div
              className={classes.grow}
              style={{ flexGrow: 1 }}
            />
            <div className={classes.sectionDesktop}>
              &nbsp;
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
        {renderMenuAdjudicacionPuestos}
      </Grid>
    </Container>
  );
};

export default withStyles(style)(Header);
