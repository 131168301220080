import React from 'react';
import Lodash from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Copyright from '../Copyright/Copyright';
import Loading from '../../Components/Loading/Loading';
import { formatData } from '../../Core/Utils/Utils';

const useStyles = makeStyles(() => ({
  divider: {
    backgroundColor: '#008945',
    margin: '20px 0',
    padding: 1,
  },
}));
const MacroList = props => {
  const {
    listName,
    btnConfig,
    tableHeads,
    tableBody,
    tableFooter,
    uiStylesClasses,
    actionConfig,
    filters,
    dataEmpty,
    showTotals,
    sumTotalsColumns,
    displayCopyright,
    isCrudAvailable,
  } = props;
  const classes = useStyles();

  const renderHeaders = () => {
    let template = [];
    let item = {};

    if (tableHeads.length > 0) {
      for (let x = 0; x < tableHeads.length; x++) {
        item = tableHeads[x];
        template.push(
          <TableCell
            key={`head-${x}`}
            style={{
              fontWeight: 'bold',
              fontSize: 11,
              padding: 3,
            }}
          >
            {item.name}
          </TableCell>,
        );
      }

      template.push(
        <TableCell key="data-options">&nbsp;</TableCell>,
      );

      template = (
        <TableHead key="row-headers">
          <TableRow style={{ textAlign: 'left' }}>
            {template}
          </TableRow>
        </TableHead>
      );
    }

    return template;
  };

  const renderTableCells = _items => {
    const template = [];
    const itemProps = Object.getOwnPropertyNames(_items);

    if (itemProps.length) {
      for (let x = 0; x < itemProps.length; x++) {
        if (
          x + 1 !== itemProps.length ||
          actionConfig().length === 0
        ) {
          template.push(
            <TableCell
              key={`row-child-id-${x}`}
              style={{ fontSize: 12, padding: 3 }}
            >
              {_items[itemProps[x]]}
            </TableCell>,
          );
        }
      }
    }

    return template;
  };

  const renderItemActions = _currentRow => {
    const cr = Lodash.get(_currentRow, ['cod_estado'], 0);

    let options = [];
    const actions = actionConfig();

    options = actions.map(option => {
      let showOpt = true;

      if (cr === 'Registrada' && option.key === 'edit') {
        showOpt = cr === 'Anulada';
      } else if (
        cr === 'Anulada' &&
        option.key === 'imprimir'
      ) {
        showOpt = false;
      } else if (
        cr === 'Inscrita' &&
        option.key === 'imprimir'
      ) {
        showOpt = false;
      } else if (
        cr === 'Anulada' &&
        option.key === 'remove'
      ) {
        showOpt = false;
      } else if (
        cr === 'Anulada' &&
        option.key === 'edit'
      ) {
        showOpt = false;
      } else if (
        cr === 'Prevención' &&
        option.key === 'edit'
      ) {
        showOpt = false;
      }

      return showOpt ? (
        <Tooltip
          title={option.aria}
          key={`tooltip-${option.key}`}
        >
          <IconButton
            key={`actions-block-${option.key}`}
            aria-label={option.aria}
            size="small"
            onClick={() => {
              if (option.clickHandler) {
                option.clickHandler(_currentRow);
              }
            }}
          >
            {option.icon}
          </IconButton>
        </Tooltip>
      ) : null;
    });

    return (
      <TableCell key={`actions-item-${_currentRow.id}`}>
        <div className={uiStylesClasses.actionContainer}>
          {options}
        </div>
      </TableCell>
    );
  };

  const renderTableData = () => {
    let template = [];
    let row = {};

    if (tableBody.length > 0) {
      for (let x = 0; x < tableBody.length; x++) {
        row = tableBody[x];
        template.push(
          <TableRow key={`row-data-${x}`}>
            {renderTableCells(row)}
            {/*renderItemActions(row)*/}
          </TableRow>,
        );
      }

      template = (
        <TableBody key="body-data">{template}</TableBody>
      );
    }

    return template;
  };

  const renderTableTotals = () => {
    let template = [];
    let totalMonto = 0;
    for (let x = 0; x < tableBody.length; x++) {
      totalMonto += parseFloat(
        tableBody[x].montoDeposito.replace(/,/g, ''),
      );
    }
    if (tableBody.length > 0) {
      template = (
        <caption key={1}>
          Registros: {tableBody.length} | Total: &cent;
          {`${formatData(totalMonto)}.00`}
        </caption>
      );
    }

    return template;
  };

  const renderRegisters = () => {
    let template = [];

    if (tableBody.length > 0) {
      template = (
        <caption key={1}>
          # Registros: {tableBody.length}
        </caption>
      );
    }

    return template;
  };

  const renderSumTotalsColumns = () => {
    let template = [];
    let row = {};

    if (tableFooter.length > 0) {
      for (let x = 0; x < tableFooter.length; x++) {
        row = tableFooter[x];
        template.push(
          <TableRow key={`row-data-${x}`}>
            {renderTableCells(row)}
            {renderItemActions(row)}
          </TableRow>,
        );
      }

      template = (
        <TableBody key="footer-data">{template}</TableBody>
      );
    }

    return template;
  };

  const renderTable = () => {
    const errorMsj = 'No se encontraron registros';
    let template = <Loading label="Cargando" />;

    if (!dataEmpty) {
      if (tableHeads.length > 0 && tableBody.length > 0) {
        template = (
          <Table size="small">
            {showTotals
              ? [
                  renderHeaders(),
                  renderTableData(),
                  renderTableTotals(),
                ]
              : [
                  renderHeaders(),
                  renderTableData(),
                  sumTotalsColumns
                    ? renderSumTotalsColumns()
                    : null,
                  renderRegisters(),
                ]}
          </Table>
        );
      }
    } else {
      template = (
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
        >
          {errorMsj}
        </Typography>
      );
    }

    return template;
  };

  React.useEffect(() => {
    // console.log(tableBody);
  });

  return (
    <Container
      maxWidth="lg"
      className={uiStylesClasses.container}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={uiStylesClasses.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
            >
              {listName}
              {isCrudAvailable && (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  className={uiStylesClasses.btnAdd}
                  onClick={() => btnConfig.event()}
                >
                  {btnConfig.name}
                </Button>
              )}
            </Typography>
            {filters || <></>}
            {/*<Divider className={classes.divider} />*/}
            {renderTable()}
            {/*<div className={uiStylesClasses.seeMore}>
              &nbsp;
            </div>*/}
          </Paper>
        </Grid>
      </Grid>
      {displayCopyright && (
        <Box pt={4}>
          <Copyright />
        </Box>
      )}
    </Container>
  );
};

MacroList.defaultProps = {
  filters: null,
  showTotals: false,
  sumTotalsColumns: false,
  displayCopyright: true,
  isCrudAvailable: true,
  tableFooter: [],
};

MacroList.propTypes = {
  listName: PropTypes.string.isRequired,
  filters: PropTypes.shape({}),
  btnConfig: PropTypes.instanceOf(Object).isRequired,
  actionConfig: PropTypes.instanceOf(Function).isRequired,
  tableHeads: PropTypes.instanceOf(Array).isRequired,
  tableBody: PropTypes.instanceOf(Array).isRequired,
  tableFooter: PropTypes.instanceOf(Array),
  uiStylesClasses: PropTypes.instanceOf(Object).isRequired,
  dataEmpty: PropTypes.bool.isRequired,
  showTotals: PropTypes.bool,
  sumTotalsColumns: PropTypes.bool,
  displayCopyright: PropTypes.bool,
  isCrudAvailable: PropTypes.bool,
};

export default MacroList;
