/* eslint-disable radix */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import Lodash from 'lodash';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import {
  Container,
  Paper,
  Grid,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Typography from '@material-ui/core/Typography';
import {
  PlnDropdown,
  PlnInput,
} from '../../Components/Input';

import { tableHeaders } from './data';
import MacroList from '../MacroList/MacroList';

import historialMFMesa from '../../Core/Api/historialMFMesaApi';
import {
  useProvincias,
  getCantonsByProvince,
  getDistritoByTwo,
} from '../../Core/Api/locationApi';

const useStyles = makeStyles(() => ({
  btnAdd: {
    float: 'right',
    width: 150,
    height: 30,
    marginTop: 5,
  },
  container: {
    paddingTop: 40,
    paddingBottom: 40,
  },
  paper: {
    padding: 20,
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

function createData(_data) {
  return {
    province: _data.des_nombre_p,
    canton: _data.des_nombre_c,
    distrito: _data.des_nombre_d,
    cedula: _data.num_cedula,
    apellido1: _data.des_apell1,
    apellido2: _data.des_apell2,
    nombre: _data.des_nombre,
    telefono: _data.tel_casa,
    celular: _data.tel_celu,
    correo: _data.des_email,
    periodo: _data.ind_periodo,
    detalle: _data.des_observacion,
    white: '',
  };
}

const HistorialMMFM = () => {
  const { data } = useProvincias();
  const [isDataEmpty, setDataEmpty] = useState(true);
  const classes = useStyles();

  // Opciones Seleccionadas
  const [sProvincia, setSProvincia] = useState(9);
  const [sCanton, setSCanton] = useState(999);
  // eslint-disable-next-line no-unused-vars
  const [sDistrito, setSDistrito] = useState(999);

  const [filteredItems, setFilteredItems] = useState([
    ['', '', '', '', '', '', '', '', '', '', '', ''],
  ]); // used for filtering
  // Lista de: Provincias, Cantones, Distritos, Nombre, Apellidos, Cedula
  const [initialValues] = useState(
    Lodash.get(data, ['initValues'], {
      provincia: 9,
      canton: 999,
      distrito: 999,
      cedula: '',
      apellido1: '',
      apellido2: '',
      nombre: '',
    }),
  );

  const provincias = Lodash.get(
    data,
    ['frmOptions'],
    [
      {
        itemLabel: '- SELECCIONE LA PROVINCIA -',
        value: 9,
      },
    ],
  );

  const [cantones, setCantones] = useState([
    { itemLabel: '- SELECCIONE EL CANTÓN-', value: 999 },
  ]);
  const [distritos, setDistritos] = useState([
    { itemLabel: '- SELECCIONE EL DISTRITO-', value: 999 },
  ]);
  const validationSchema = Yup.object({});

  const setProvincia = p => {
    setCantones([
      {
        itemLabel: '- SELECCIONE EL CANTÓN-',
        value: 999,
      },
    ]);
    setDistritos([
      {
        itemLabel: '- SELECCIONE EL DISTRITO-',
        value: 999,
      },
    ]);
    initialValues.provincia = p;
    initialValues.canton = 999;
    initialValues.distrito = 999;
    if (p !== 9) {
      getCantonsByProvince(p).then(result => {
        if (result.status === 200) {
          const cantonsList = Lodash.get(result, [
            'data',
            'response',
          ]);

          const clist = cantonsList.map(
            ({ cod_canton, des_nombre_c }) => {
              return {
                itemLabel: des_nombre_c,
                value: cod_canton,
              };
            },
          );
          clist.unshift({
            itemLabel: '- SELECCIONE EL CANTÓN-',
            value: 999,
          });

          setSProvincia(
            Lodash.find(provincias, {
              value: p,
            }),
          );
          setCantones(clist);
        }
      });
    }
  };

  const setCanton = c => {
    initialValues.distrito = 999;
    initialValues.provincia = sProvincia.value;
    initialValues.canton = c;

    if (c !== 999) {
      getDistritoByTwo(sProvincia.value, c).then(result => {
        if (result.status === 200) {
          const distritosList = Lodash.get(result, [
            'data',
            'response',
          ]);
          const dlist = distritosList.map(
            ({ cod_distrito, des_nombre_d }) => {
              return {
                itemLabel: des_nombre_d,
                value: cod_distrito,
              };
            },
          );
          dlist.unshift({
            itemLabel: '- SELECCIONE EL DISTRITO-',
            value: 999,
          });

          setSCanton(
            Lodash.find(cantones, {
              value: c,
            }),
          );

          setDistritos(dlist);
        }
      });
    } else {
      setDistritos([
        {
          itemLabel: '- SELECCIONE EL DISTRITO-',
          value: 999,
        },
      ]);
    }
  };

  const setDistrito = d => {
    initialValues.provincia = sProvincia.value;
    initialValues.canton = sCanton.value;
    initialValues.distrito = d;
    if (d !== 999) {
      setSDistrito(
        Lodash.find(distritos, {
          value: d,
        }),
      );
    } else {
      setSDistrito({ itemLabel: '', value: 0 });
    }
  };

  const doSearch = values => async () => {
    const {
      canton,
      distrito,
      provincia,
      cedula,
      nombre,
      apellido1,
      apellido2,
    } = values;
    const codProvincia = provincia === 9 ? -1 : provincia;
    const codCanton = canton === 999 ? -1 : canton;
    const codDistrito =
      distrito === 999 ? -1 : parseInt(distrito);
    const numCedula = cedula === '' ? '' : cedula;
    const nNombre = nombre === '' ? '' : nombre;
    const nApellido1 = apellido1 === '' ? '' : apellido1;
    const nApellido2 = apellido2 === '' ? '' : apellido2;

    if (
      codProvincia !== -1 &&
      codCanton !== -1 &&
      codDistrito !== -1
    ) {
      const result = await historialMFMesa.getAll({
        codProvincia,
        codCanton,
        codDistrito,
        numCedula,
        nNombre,
        nApellido1,
        nApellido2,
      });

      const info = Lodash.get(
        result,
        ['data', 'response'],
        [],
      );
      if (info instanceof Object && info.length > 0) {
        const rowItems = info.map(row => {
          return createData(row);
        });
        setFilteredItems(rowItems);
        setDataEmpty(false);
      } else {
        setFilteredItems([]);
        setDataEmpty(true);
      }
    }
  };

  /**
   * List all actions that a user can do for each item in the list
   */
  const setActions = () => {
    const actions = [];

    return actions;
  };

  const renderForm = () => {
    return (
      <div className={classes.boxForm}>
        <Grid container spacing={3}>
          <Grid
            item
            xs={4}
            style={{
              paddingBottom: 0,
            }}
          >
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Provincia
            </h4>
            <Field
              name="provincia"
              data={provincias}
              component={PlnDropdown}
              onChange={provincia =>
                setProvincia(provincia)
              }
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              paddingBottom: 0,
            }}
          >
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Cantón
            </h4>
            <Field
              name="canton"
              data={cantones}
              component={PlnDropdown}
              onChange={canton => setCanton(canton)}
            />
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              paddingBottom: 0,
            }}
          >
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Distrito Electoral
            </h4>
            <Field
              name="distrito"
              data={distritos}
              component={PlnDropdown}
              onChange={distrito => setDistrito(distrito)}
            />
          </Grid>
          <Grid item xs={3}>
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Cedula
            </h4>
            <Field
              mask="999999999"
              name="cedula"
              component={PlnInput}
              type="text"
              style={{
                marginTop: 10,
                padding: '15px 15px',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Apellido 1
            </h4>
            <Field
              name="apellido1"
              component={PlnInput}
              type="text"
              style={{
                marginTop: 10,
                padding: '15px 15px',
                textTransform: 'uppercase',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Apellido 2
            </h4>
            <Field
              name="apellido2"
              component={PlnInput}
              type="text"
              style={{
                marginTop: 10,
                padding: '15px 15px',
                textTransform: 'uppercase',
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <h4
              style={{
                marginBlockStart: 0,
                marginBlockEnd: 0,
              }}
            >
              Nombre
            </h4>
            <Field
              name="nombre"
              component={PlnInput}
              type="text"
              style={{
                marginTop: 10,
                padding: '15px 15px',
                textTransform: 'uppercase',
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography
              component="h2"
              variant="h6"
              color="primary"
              gutterBottom
              style={{
                borderBottom: 'solid thin #008945',
                marginBottom: 20,
              }}
            >
              Historial Miembros y Fiscales de Mesa
            </Typography>

            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {formik => (
                <>
                  {renderForm()}
                  <Grid container spacing={0}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        textAlign: 'right',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={doSearch(formik.values)}
                        style={{
                          marginTop: 0,
                          height: 30,
                          fontSize: 12,
                        }}
                      >
                        BUSCAR
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
      <MacroList
        dataEmpty={isDataEmpty}
        listName=""
        uiStylesClasses={classes}
        btnConfig={{}}
        actionConfig={setActions}
        tableHeads={tableHeaders}
        tableBody={filteredItems}
        displayCopyright={false}
        isCrudAvailable={false}
      />
    </Container>
  );
};

export default HistorialMMFM;
